import * as React from "react"
import {graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {MainWrap} from '../pages/web.js'
import {BlogArticleWrap} from '../pages/index.js'
import Articles from "../components/articles"

const Blog = ({data, location}) => {
  const posts = data.allMarkdownRemark.nodes
  return (
    <Layout location={location}>
      <Seo
        title="ブログ記事一覧"
        description="神奈川でホームページ作成TAN-ZENTのブログ記事一覧です。"
        pagepath={location.pathname}
      />
      <MainWrap>
        <div className="-jacket">
          <h1>ブログ記事一覧</h1>
          <BlogArticleWrap>
            {posts.map((post, i) => {
              const title = post.frontmatter.title || post.fields.slug
              return (
                <Articles
                  post={post}
                  title={title}
                  titileTag="h2"
                 />
              )
          })}
          </BlogArticleWrap>
        </div>
      </MainWrap>
    </Layout>
  )

}
export default Blog
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt(pruneLength: 120)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY年MM月DD日")
          title
          description
          tags
        }
      }
    }
  }
`
